import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link, SelectionGroup, Checkbox, RadioButton } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/selection-group/code",
  "title": "SelectionGroup - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "vertical-group-checkbox",
      "style": {
        "position": "relative"
      }
    }}>{`Vertical group (Checkbox)`}<a parentName="h4" {...{
        "href": "#vertical-group-checkbox",
        "aria-label": "vertical group checkbox permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const [checkedItems, setCheckedItems] = React.useState({ "v-checkbox1": true, "v-checkbox2": true });
  const onChange = (event) => {
    setCheckedItems({ ...checkedItems, [event.target.name]: event.target.checked });
  };
  return (
    <SelectionGroup label="Vertical checkbox group">
      <Checkbox id="v-checkbox1" name="v-checkbox1" label="Option" checked={checkedItems["v-checkbox1"]} onChange={onChange} />
      <Checkbox id="v-checkbox2" name="v-checkbox2" label="Option" checked={checkedItems["v-checkbox2"]} onChange={onChange} />
      <Checkbox id="v-checkbox3" name="v-checkbox3" label="Option" checked={checkedItems["v-checkbox3"]} onChange={onChange} />
      <Checkbox id="v-checkbox4" name="v-checkbox4" label="Option" checked={checkedItems["v-checkbox4"]} onChange={onChange} />
    </SelectionGroup>
  );
}}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<fieldset class="hds-selection-group">
  <legend class="hds-selection-group__legend">Vertical checkbox group</legend>
  <div class="hds-selection-group__items">
    <div class="hds-selection-group__item">
      <div class="hds-checkbox">
        <input type="checkbox" id="v-checkbox1" name="v-checkbox1" class="hds-checkbox__input" value="foo" checked />
        <label for="v-checkbox1" class="hds-checkbox__label">Option</label>
      </div>
    </div>
    <div class="hds-selection-group__item">
      <div class="hds-checkbox">
        <input type="checkbox" id="v-checkbox2" name="v-checkbox2" class="hds-checkbox__input" value="bar" checked/>
        <label for="v-checkbox2" class="hds-checkbox__label">Option</label>
      </div>
    </div>
    <div class="hds-selection-group__item">
      <div class="hds-checkbox">
        <input type="checkbox" id="v-checkbox3" name="v-checkbox3" class="hds-checkbox__input" value="baz" />
        <label for="v-checkbox3" class="hds-checkbox__label">Option</label>
      </div>
    </div>
    <div class="hds-selection-group__item">
      <div class="hds-checkbox">
        <input type="checkbox" id="v-checkbox4" name="v-checkbox4" class="hds-checkbox__input" value="qux" />
        <label for="v-checkbox4" class="hds-checkbox__label">Option</label>
      </div>
    </div>
  </div>
</fieldset>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "vertical-group-radiobutton",
      "style": {
        "position": "relative"
      }
    }}>{`Vertical group (RadioButton)`}<a parentName="h4" {...{
        "href": "#vertical-group-radiobutton",
        "aria-label": "vertical group radiobutton permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const [selectedItem, setSelectedItem] = React.useState("1");
  const onChange = (event) => {
    setSelectedItem(event.target.value);
  };
  return (
    <SelectionGroup label="Vertical radio group">
      <RadioButton id="v-radio1" name="v-radio" value="1" label="Option" checked={selectedItem === "1"} onChange={onChange} />
      <RadioButton id="v-radio2" name="v-radio" value="2" label="Option" checked={selectedItem === "2"} onChange={onChange} />
      <RadioButton id="v-radio3" name="v-radio" value="3" label="Option" checked={selectedItem === "3"} onChange={onChange} />
      <RadioButton id="v-radio4" name="v-radio" value="4" label="Option" checked={selectedItem === "4"} onChange={onChange} />
    </SelectionGroup>
  );
}}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<fieldset class="hds-selection-group">
  <legend class="hds-selection-group__legend">Vertical radio group</legend>
  <div class="hds-selection-group__items">
    <div class="hds-selection-group__item">
      <div class="hds-radio-button">
        <input type="radio" id="v-radio1" name="v-radio" class="hds-radio-button__input" value="foo" checked />
        <label for="v-radio1" class="hds-radio-button__label">Option</label>
      </div>
    </div>
    <div class="hds-selection-group__item">
      <div class="hds-radio-button">
        <input type="radio" id="v-radio2" name="v-radio" class="hds-radio-button__input" value="bar" />
        <label for="v-radio2" class="hds-radio-button__label">Option</label>
      </div>
    </div>
    <div class="hds-selection-group__item">
      <div class="hds-radio-button">
        <input type="radio" id="v-radio3" name="v-radio" class="hds-radio-button__input" value="baz" />
        <label for="v-radio3" class="hds-radio-button__label">Option</label>
      </div>
    </div>
    <div class="hds-selection-group__item">
      <div class="hds-radio-button">
        <input type="radio" id="v-radio4" name="v-radio" class="hds-radio-button__input" value="qux" />
        <label for="v-radio4" class="hds-radio-button__label">Option</label>
      </div>
    </div>
  </div>
</fieldset>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "horizontal-group-checkbox",
      "style": {
        "position": "relative"
      }
    }}>{`Horizontal group (Checkbox)`}<a parentName="h4" {...{
        "href": "#horizontal-group-checkbox",
        "aria-label": "horizontal group checkbox permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const [checkedItems, setCheckedItems] = React.useState({ "h-checkbox1": true, "h-checkbox2": true });
  const onChange = (event) => {
    setCheckedItems({ ...checkedItems, [event.target.name]: event.target.checked });
  };
  return (
    <SelectionGroup label="Horizontal checkbox group" direction="horizontal">
      <Checkbox id="h-checkbox1" name="h-checkbox1" label="Option" checked={checkedItems["h-checkbox1"]} onChange={onChange} />
      <Checkbox id="h-checkbox2" name="h-checkbox2" label="Option" checked={checkedItems["h-checkbox2"]} onChange={onChange} />
      <Checkbox id="h-checkbox3" name="h-checkbox3" label="Option" checked={checkedItems["h-checkbox3"]} onChange={onChange} />
    </SelectionGroup>
  );
}}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<fieldset class="hds-selection-group">
  <legend class="hds-selection-group__legend">Horizontal checkbox group</legend>
  <div class="hds-selection-group__items hds-selection-group__items--horizontal">
    <div class="hds-selection-group__item">
      <div class="hds-checkbox">
        <input type="checkbox" id="h-checkbox1" name="h-checkbox1" class="hds-checkbox__input" value="foo" checked />
        <label for="h-checkbox1" class="hds-checkbox__label">Option</label>
      </div>
    </div>
    <div class="hds-selection-group__item">
      <div class="hds-checkbox">
        <input type="checkbox" id="h-checkbox2" name="h-checkbox2" class="hds-checkbox__input" value="bar" checked/>
        <label for="h-checkbox2" class="hds-checkbox__label">Option</label>
      </div>
    </div>
    <div class="hds-selection-group__item">
      <div class="hds-checkbox">
        <input type="checkbox" id="h-checkbox3" name="h-checkbox3" class="hds-checkbox__input" value="baz" />
        <label for="h-checkbox3" class="hds-checkbox__label">Option</label>
      </div>
    </div>
  </div>
</fieldset>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "horizontal-group-radiobutton",
      "style": {
        "position": "relative"
      }
    }}>{`Horizontal group (RadioButton)`}<a parentName="h4" {...{
        "href": "#horizontal-group-radiobutton",
        "aria-label": "horizontal group radiobutton permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const [selectedItem, setSelectedItem] = React.useState("1");
  const onChange = (event) => {
    setSelectedItem(event.target.value);
  };
  return (
    <SelectionGroup label="Horizontal radio group" direction="horizontal">
      <RadioButton id="h-radio1" name="h-radio" value="1" label="Option" checked={selectedItem === "1"} onChange={onChange} />
      <RadioButton id="h-radio2" name="h-radio" value="2" label="Option" checked={selectedItem === "2"} onChange={onChange} />
      <RadioButton id="h-radio3" name="h-radio" value="3" label="Option" checked={selectedItem === "3"} onChange={onChange} />
    </SelectionGroup>
  );
}}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<fieldset class="hds-selection-group">
  <legend class="hds-selection-group__legend">Horizontal radio group</legend>
  <div class="hds-selection-group__items hds-selection-group__items--horizontal">
    <div class="hds-selection-group__item">
      <div class="hds-radio-button">
        <input type="radio" id="h-radio1" name="h-radio" class="hds-radio-button__input" value="foo" checked />
        <label for="h-radio1" class="hds-radio-button__label">Option</label>
      </div>
    </div>
    <div class="hds-selection-group__item">
      <div class="hds-radio-button">
        <input type="radio" id="h-radio2" name="h-radio" class="hds-radio-button__input" value="bar" />
        <label for="h-radio2" class="hds-radio-button__label">Option</label>
      </div>
    </div>
    <div class="hds-selection-group__item">
      <div class="hds-radio-button">
        <input type="radio" id="h-radio3" name="h-radio" class="hds-radio-button__input" value="baz" />
        <label for="h-radio3" class="hds-radio-button__label">Option</label>
      </div>
    </div>
  </div>
</fieldset>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-selectiongroup--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react/src/components/selectionGroup" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/core/?path=/story/components-selection-group--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core/src/components/selection-group" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/docs/components-selectiongroup--default" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label for the selection group.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`direction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The direction in which child components should be rendered in.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"vertical"`}</inlineCode>{` `}<inlineCode parentName="td">{`"horizontal"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"vertical"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:SelectionGroup properties]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      